<script>
import appConfig from "@/app.config";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
// import moment from "moment";

export default {
  page: {
    title: "Penjadwalan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Datatable,
    Pagination,
  },
  data() {
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "ID Jadwal", name: "-" },
      { width: "auto", label: "Batch", name: "-" },
      { width: "auto", label: "Tanggal Batch", name: "-" },
      { width: "auto", label: "NIP", name: "-" },
      { width: "auto", label: "Peserta", name: "-" },
      { width: "auto", label: "Jabatan", name: "-" },
      { width: "auto", label: "Unit", name: "-" },
      { width: "auto", label: "Kompetensi", name: "-" },
      { width: "auto", label: "Level", name: "-" },
      { width: "auto", label: "Pertanyaan", name: "-" },
      { width: "auto", label: "Jawaban Peserta", name: "-" },
      { width: "auto", label: "Skor", name: "-" },
      { width: "auto", label: "Ujian Ke-", name: "-" },
    ];
    return {
      isWrite: localStorage.getItem("isWrite"),
      title: "Jawaban Peserta",
      items: [
        {
          text: "Report Hasil",
          active: false,
        },
        {
          text: "Kompetensi Peserta",
          active: true,
        },
      ],
      isLoading: false,
      columns: columns,
      tableFilter: {
        page: 1,
        per_page: 10,
        nip: "",
        nama: "",
        status: 1,
        idjadwal: "",
        nama_batch: "",
        jabatan: "",
        kompetensi: "",
        unit: "",
        level: "",
      },
      tableData: [],
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      optionFilterJadwal: [],
      optionFilterBatch: [],
      optionFilterJabatan: [],
      optionFilterKompetensi: [],
      optionFilterLevel: [],
    };
  },
  mounted() {
    this.getData();
    this.getFilterJadwal();
    this.getFilterBatch();
    this.getFilterJabatan();
    this.getFilterKompetensi();
    this.getFilterLevel();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const data = await axios({
        methods: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "report-hasil/jawaban-peserta",
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
        params: this.tableFilter,
      })
        .then((response) => response.data)
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta?.code == 403) {
            Swal.fire({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: errResponse.meta.message,
            });
            this.$router.push({ name: "logout" });
            return false;
          }
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });
      if (data) {
        if (data.meta.code == 403) {
          this.$router.push("logout");
        } else if (data.meta.code == 200) {
          this.tableData = data.data.data;
          this.pagination.lastPage = data.data.last_page;
          this.pagination.currentPage = data.data.current_page;
          this.pagination.total = data.data.total;
          this.pagination.lastPageUrl = data.data.last_page_url;
          this.pagination.nextPageUrl = data.data.next_page_url;
          this.pagination.prevPageUrl = data.data.prev_page_url;
          this.pagination.from = data.data.from;
          this.pagination.to = data.data.to;
          this.pagination.links = data.data.links;
        }
      }
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    async searchJadwal(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterJadwal(search, loading);
      }
    },
    async getFilterJadwal(search = "", loading) {
      await axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/jadwal/all", {
          params: {
            search: search,
            limit: 10,
          },
          headers: {
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          this.optionFilterJadwal = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchBatch(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterBatch(search, loading);
      }
    },
    async getFilterBatch(search = "", loading) {
      await axios({
        method: "get",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/jadwal-batch/all",
        params: {
          search: search,
          limit: 10,
        },
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      }).then((response) => {
        this.optionFilterBatch = response.data.data;
        if (loading) loading(false);
      });
    },
    async searchJabatanKompetensi(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterJabatan(search, loading);
      }
    },
    async getFilterJabatan(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/jabatan-kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterJabatan = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchKompetensi(search, loading) {
      if (search.length) {
        loading(true);
        this.getFilterKompetensi(search, loading);
      }
    },
    async getFilterKompetensi(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterKompetensi = response.data.data;
          if (loading) loading(false);
        });
    },
    async getFilterLevel(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "referensi/level-kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterLevel = response.data.data.data;
          if (loading) loading(false);
        });
    },
    exportData() {
      let params = new URLSearchParams(this.tableFilter).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "report-hasil/export-excel/jawaban-peserta?" +
          params
      );
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="d-flex justify-content-between">
          <div class="card-title">Jawaban Peserta</div>
          <button
            class="btn btn-sm btn-success"
            @click="exportData()"
            v-if="isWrite == 1"
          >
            <i class="fa fa-file-excel me-1"></i> Export Report Peserta
          </button>
        </div>
        <div class="col-md-12">
          <hr />
          <div class="row">
            <div class="col-md-3">
              <label for="filterJadwal">Jadwal</label>
              <v-select
                id="parent_menu"
                class="style-chooser"
                :options="optionFilterJadwal"
                :reduce="(jadwal) => jadwal.idjadwal"
                label="kode_tes"
                v-model="tableFilter.idjadwal"
                placeholder="Filter Jadwal"
                @search="searchJadwal"
              >
              </v-select>
            </div>
            <div class="col-md-3">
              <label for="filterBatch">Batch</label>
              <v-select
                id="parent_menu"
                class="style-chooser"
                :options="optionFilterBatch"
                :reduce="(batch) => batch.nama_batch"
                label="nama_batch"
                v-model="tableFilter.nama_batch"
                placeholder="Filter Jabatan"
                @search="searchJadwalKompetensi"
              >
              </v-select>
            </div>
            <div class="col-md-3">
              <label for="filterJabatan">Jabatan</label>
              <v-select
                id="parent_menu"
                class="style-chooser"
                :options="optionFilterJabatan"
                :reduce="(jabatan) => jabatan.nama_jabatan"
                label="nama_jabatan"
                v-model="tableFilter.jabatan"
                placeholder="Filter Jabatan"
                @search="searchJabatanKompetensi"
              >
              </v-select>
            </div>
            <div class="col-md-3">
              <label for="filterKompetensi">Kompetensi</label>
              <v-select
                id="parent_menu"
                class="style-chooser"
                :options="optionFilterKompetensi"
                :reduce="(komptensi) => komptensi.nama_kompetensi"
                label="nama_kompetensi"
                v-model="tableFilter.kompetensi"
                placeholder="Filter Kompetensi"
                @search="searchKompetensi"
              >
              </v-select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3">
              <label for="filterNama">Nama</label>
              <input
                type="text"
                class="form-control"
                id="tanggal_selesai"
                v-model="tableFilter.nama"
                required
              />
            </div>
            <div class="col-md-3">
              <label for="FilterNIP">Nip</label>
              <input
                type="text"
                class="form-control"
                id="tanggal_selesai"
                v-model="tableFilter.nip"
                required
              />
            </div>
            <div class="col-md-3">
              <label for="FilterUnit">Unit</label>
              <input
                type="text"
                class="form-control"
                id="tanggal_selesai"
                v-model="tableFilter.unit"
                required
              />
            </div>
            <div
              class="col-md-3 d-flex align-items-end justify-content-end gap-2"
            >
              <div class="form-group flex-fill">
                <label for="FilterUnit">Level</label>
                <v-select
                  id="parent_menu"
                  class="style-chooser"
                  :options="optionFilterLevel"
                  :reduce="(level) => level.nama_level"
                  label="nama_level"
                  v-model="tableFilter.level"
                  placeholder="Filter Level"
                >
                </v-select>
              </div>
              <button type="button" class="btn btn-primary" @click="getData()">
                <i class="fa fa-filter me-1"></i> Filter
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div class="col-md-12 mb-2">
          <div class="row">
            <div class="col-md-4">
              <table>
                <tr>
                  <td>Show</td>
                  <td>
                    <select
                      class="form-control form-control-sm"
                      id="showpaginatetable"
                      style="width: 100%"
                      v-model="tableFilter.per_page"
                      @change="getData"
                    >
                      <option value="10" selected>10</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </select>
                  </td>
                  <td>Entries</td>
                </tr>
              </table>
            </div>
            <div class="col-md-5">&nbsp;</div>
            <div class="col-md-3">
              <!-- <input
                type="text"
                class="form-control form-control-sm"
                id="searchTable"
                placeholder="Cari Data ..."
                v-model="tableData.search"
                @input="getDataTable()"
              /> -->
            </div>
          </div>
        </div>
        <datatable :columns="columns">
          <tbody>
            <tr v-if="isLoading">
              <td colspan="15" class="text-center">
                <i class="fas fa-spinner fa-spin"></i> Loading...
              </td>
            </tr>
            <tr v-else-if="tableData == ''">
              <td class="text-center" colspan="99">Data Tidak Tersedia</td>
            </tr>
            <tr v-else v-for="(row, index) in tableData" :key="index">
              <td class="text-center">
                {{ index + pagination.from }}
              </td>
              <td class="text-center">
                {{ row.idjadwal }}
              </td>
              <td>
                {{ row.nama_batch }}
              </td>
              <td>
                {{ row.waktu_mulai }}
              </td>
              <td>
                {{ row.nip }}
              </td>
              <td>
                {{ row.nama }}
              </td>
              <td>
                {{ row.jabatan_asesmen }}
              </td>
              <td>
                {{ row.unit }}
              </td>
              <td>
                {{ row.nama_kompetensi }}
              </td>
              <td>
                {{ row.nama_level }}
              </td>
              <td>
                {{ row.pertanyaan }}
              </td>
              <td>
                {{ row.kode_jawaban }}
              </td>
              <td>
                {{ row.skor }}
              </td>
              <td>
                {{ row.ujian_ke }}
              </td>
            </tr>
          </tbody>
        </datatable>
      </div>
      <pagination :pagination="pagination" @to-page="toPage"></pagination>
    </div>
  </div>
</template>
